import store from '@/store'
import {
  mdiAlertCircleOutline,
  mdiChartTimelineVariant,
  mdiCheckCircleOutline,
  mdiClose,
  mdiContentSaveOutline,
  mdiDownloadCircleOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { ref, watch } from 'vue'

export default function useMaterialsList() {
  const materialListTable = ref([])

  // Table Handlers
  const tableColumns = [
    /* {
      text: '#ID',
      align: 'start',
      value: 'id',
    }, */
    { text: 'DATE', value: 'created_at' },
    { text: 'TITLE', value: 'title' },
    { text: 'PUBLISHED', value: 'published' },
    { text: 'SOLD', value: 'sold' },

    // { text: 'DESCRIPTION', value: 'total' },
    // { text: 'LOCATION', value: 'location' },
    { text: 'TYPE', value: 'subtype' },
    { text: 'AMOUNT', value: 'amount' },
    { text: 'PRICE', value: 'price' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['created_at'],
    sortDesc: [true],
  })
  const totalMaterialListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const sortMaterials = materials => {
    const materialsObj = JSON.parse(materials)
    materialListTable.value = materialsObj
    totalMaterialListTable.value = materialsObj.length

    console.log(options.value.sortBy[0], options.value.sortDesc[0])

    // Sort
    materialListTable.value.sort((a, b) => {
      const el = options.value.sortBy[0]
      const sortDesc = options.value.sortDesc[0]

      if (el === undefined && sortDesc === undefined) {
        return 0
      }

      let obj1 = a[el]
      let obj2 = b[el]

      if (el === 'created_at') {
        obj1 = obj1.seconds
        obj2 = obj2.seconds
      } else if (el === 'price') {
        obj1 = parseFloat(obj1)
        obj2 = parseFloat(obj2)
      } else {
        obj1 = obj1.toString()
        obj2 = obj2.toString()
      }
      if (sortDesc) {
        if (obj1 > obj2) return -1
        if (obj2 > obj1) return 1

        return 0
      }

      if (obj1 > obj2) return 1
      if (obj2 > obj1) return -1

      return 0
    })
  }

  const fetchMaterialsByUser = () => {
    const materials = localStorage.getItem('materials-list')
    if (materials === undefined || materials === null) {
      store
        .dispatch('app-material/fetchMaterialsByUser')
        .then(response => {
          materialListTable.value = response
          totalMaterialListTable.value = response.length
          loading.value = false
          localStorage.setItem('materials-list', JSON.stringify(response))

          sortMaterials(JSON.stringify(response))
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      loading.value = false
      sortMaterials(materials)
    }
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchMaterialsByUser()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMaterialStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: mdiChartTimelineVariant }
    if (status === 'Paid') return { variant: 'success', icon: mdiCheckCircleOutline }
    if (status === 'Downloaded') return { variant: 'info', icon: mdiDownloadCircleOutline }
    if (status === 'Draft') return { variant: 'secondary', icon: mdiContentSaveOutline }
    if (status === 'Sent') return { variant: 'primary', icon: mdiEmailOutline }
    if (status === 'Past Due') return { variant: 'error', icon: mdiAlertCircleOutline }

    return { variant: 'secondary', icon: mdiClose }
  }

  return {
    tableColumns,
    searchQuery,
    options,
    materialListTable,
    statusFilter,
    totalMaterialListTable,
    loading,
    selectedTableData,
    fetchMaterialsByUser,
    resolveMaterialStatusVariantAndIcon,
  }
}
