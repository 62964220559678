<template>
  <v-card id="material-list">
    <v-dialog
      v-if="materialToBeDeleted"
      v-model="isDeleteConfirm"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Material deletion confirmation
        </v-card-title>
        <v-card-text>
          This action is permanent, you cannot restore <strong><i>{{ materialToBeDeleted.title }}</i></strong> once it has been DELETED. Please click on 'Confirm' to confirm the deletion. Otherwise click 'Cancel'
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDeletion"
          >
            Confirm
          </v-btn>
          <v-btn
            color="primary"
            @click="isDeleteConfirm = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDeletesConfirm"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Material deletions confirmation
        </v-card-title>
        <v-card-text>
          This action is permanent, you cannot restore materials selected once it has been DELETED. Please click on 'Confirm' to confirm the deletions. Otherwise click 'Cancel'
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDeletions"
          >
            Confirm
          </v-btn>
          <v-btn
            color="primary"
            @click="isDeletesConfirm = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0 pt-5">
      <div class="d-flex align-center pb-5">
        <!-- actions -->
        <v-select
          v-model="selectedAction"
          label="Actions"
          single-line
          outlined
          dense
          :items="actions"
          hide-details
          :disabled="Boolean(!selectedTableData.length)"
          class="invoice-list-actions me-3"
          @change="bulkAction"
        ></v-select>

        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'materials-add' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create a new post</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Material"
          class="invoice-list-search me-3"
        ></v-text-field>
        <!--
        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>
        -->
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="materialListTable"
      :options.sync="options"
      :server-items-length="totalMaterialListTable"
      :loading="loading"
      show-select
      class="text-no-wrap"
    >
      <!-- id
      <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'materials-edit', params: { id: item.id } }"
        >
          {{ item.id }}
        </router-link>
      </template>
      -->

      <!-- created -->
      <template #[`item.created_at`]="{item}">
        {{ humanReadable(item.created_at) }}
      </template>

      <!-- total -->
      <template #[`item.price`]="{item}">
        ${{ item.price }}
      </template>

      <!-- Published -->
      <template #[`item.published`]="{item}">
        <span v-if="item.published">
          {{ humanReadable(item.published_on) }}
        </span>
        <span v-else>
          Not yet
        </span>
      </template>

      <!-- Sold -->
      <template #[`item.sold`]="{item}">
        <span v-if="item.sold">
          {{ humanReadable(item.sold_on) }}
        </span>
        <span v-else>
          Not yet
        </span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- delete -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteMaterial(item)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>

          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'materials-edit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit Material</span>
          </v-tooltip>

          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.published"
                href="javascript:void(0)"
                @click="unpublish(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiShareOff }}
                  </v-icon>
                  <span>Unpublish</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.published"
                href="javascript:void(0)"
                @click="publish(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiShare }}
                  </v-icon>
                  <span>Publish</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.sold"
                href="javascript:void(0)"
                @click="unsold(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiCurrencyUsdOff }}
                  </v-icon>
                  <span>Unsold</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.sold"
                href="javascript:void(0)"
                @click="sold(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiCurrencyUsd }}
                  </v-icon>
                  <span>Sold</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiCurrencyUsd,
  mdiCurrencyUsdOff,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiPlus,
  mdiShare,
  mdiShareOff,
} from '@mdi/js'
import moment from 'moment'

import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref } from 'vue'

// store module
import materialStoreModule from '../materialStoreModule'

// composition function
import useMaterialsList from './useMaterialList'

export default {
  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })

    const materialToBeDeleted = ref(null)
    const isDeleteConfirm = ref(false)

    const {
      materialListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalMaterialListTable,
      loading,
      selectedTableData,
      resolveMaterialStatusVariantAndIcon,
    } = useMaterialsList()

    const statusOptions = ref(['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due'])

    const actions = ['Delete']

    const selectedAction = ref('')
    const isDeletesConfirm = ref(false)
    const isPublishesConfirm = ref(false)
    const isSoldsConfirm = ref(false)

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const bulkAction = () => {
      if (selectedAction.value === 'Delete') {
        isDeletesConfirm.value = true
      }
      if (selectedAction.value === 'Publish') {
        isPublishesConfirm.value = true
      }
      if (selectedAction.value === 'Sold') {
        isSoldsConfirm.value = true
      }
    }

    const confirmDeletions = () => {
      console.log(selectedAction.value)
      console.log(selectedTableData)
      if (selectedAction.value === 'Delete') {
        store
          .dispatch('app-material/deleteMaterials', selectedTableData.value)
          .then(() => {
            selectedTableData.value.forEach(itemId => {
              const itemIndex = materialListTable.value.findIndex(m => m.id === itemId.id)
              materialListTable.value.splice(itemIndex, 1)

              // Update local storage
              localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))
            })
            
            // Reset values
            isDeletesConfirm.value = false
          })
          .catch(err => {
            console.log(err)
          })
      }
    }

    const deleteMaterial = item => {
      console.log(item)
      materialToBeDeleted.value = item
      isDeleteConfirm.value = true
    }

    const confirmDeletion = async () => {
      await store
        .dispatch('app-material/deleteMaterial', materialToBeDeleted.value)
        .then(() => {
          const itemIndex = materialListTable.value.findIndex(m => m.id === materialToBeDeleted.value.id)
          materialListTable.value.splice(itemIndex, 1)

          // Update local storage
          localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))

          // Reset values
          materialToBeDeleted.value = null
          isDeleteConfirm.value = false
        })
        .catch(err => {
          console.log(err)
        })
    }

    const unpublish = async item => {
      await store
        .dispatch('app-material/updateMaterial', { itemId: item.id, payload: { published: false } })
        .then(() => {
          const itemIndex = materialListTable.value.findIndex(m => m.id === item.id)
          materialListTable.value[itemIndex].published = false

          // Update local storage
          localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))
        })
    }

    const publish = async item => {
      await store
        .dispatch('app-material/updateMaterial', { itemId: item.id, payload: { published: true } })
        .then(() => {
          const itemIndex = materialListTable.value.findIndex(m => m.id === item.id)
          materialListTable.value[itemIndex].published = true
          materialListTable.value[itemIndex].published_on = moment().format('MM/DD/yyyy hh:mma')

          // Update local storage
          localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))
        })
    }

    const unsold = async item => {
      await store.dispatch('app-material/updateMaterial', { itemId: item.id, payload: { sold: false } }).then(() => {
        const itemIndex = materialListTable.value.findIndex(m => m.id === item.id)
        materialListTable.value[itemIndex].sold = false

        // Update local storage
        localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))
      })
    }

    const sold = async item => {
      await store.dispatch('app-material/updateMaterial', { itemId: item.id, payload: { sold: true } }).then(() => {
        const itemIndex = materialListTable.value.findIndex(m => m.id === item.id)
        materialListTable.value[itemIndex].sold = true
        materialListTable.value[itemIndex].sold_on = moment().format('MM/DD/yyyy hh:mma')

        // Update local storage
        localStorage.setItem('materials-list', JSON.stringify(materialListTable.value))
      })
    }

    const humanReadable = dtobj => {
      if (dtobj.seconds !== undefined) {
        return moment(dtobj.seconds * 1000).format('MM/DD/yyyy hh:mma')
      }

      return dtobj
    }

    return {
      tableColumns,
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalMaterialListTable,
      materialListTable,
      loading,
      actions,
      selectedTableData,
      selectedAction,
      materialToBeDeleted,
      isDeleteConfirm,
      isDeletesConfirm,
      isPublishesConfirm,
      isSoldsConfirm,

      checkType,
      avatarText,
      resolveMaterialStatusVariantAndIcon,
      humanReadable,
      deleteMaterial,
      confirmDeletion,
      publish,
      unpublish,
      sold,
      unsold,
      bulkAction,
      confirmDeletions,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiShare,
        mdiShareOff,
        mdiCurrencyUsd,
        mdiCurrencyUsdOff,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#material-list {
  .material-list-actions {
    max-width: 7.81rem;
  }
  .material-list-search {
    max-width: 10.625rem;
  }
  .material-list-status {
    max-width: 11.3rem;
  }
}
</style>
