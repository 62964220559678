import {
  addDoc,
  collection,
  dbapp,
  deleteDoc,
  deleteObject,
  doc,
  GeoPoint,
  getDoc,
  setDoc,
  getDocs,
  getDownloadURL,
  getStorage,
  query,
  ref,
  Timestamp,
  updateDoc,
  uploadBytes,
  onSnapshot,
  where,
} from '../firebaseConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async subToMaterials(ctx, cbfunc) {
      console.log("Subscribed")

      const q = query(collection(dbapp, 'materials'))
      
      onSnapshot(q, (querySnap) => {
        querySnap.docChanges().forEach(change => {
          cbfunc(change.type, change.doc)
        })
      })
    },

    async getUserById(ctx, userId) {
      const response = await getDoc(doc(dbapp, 'users', userId))

      return response
    },

    async deleteImage(ctx, imageURL) {
      const storage = getStorage()
      const httpsReference = ref(storage, imageURL)

      return deleteObject(httpsReference)
        .then(() => ({
          success: true,
          msg: 'done',
        }))
        .catch(error => ({
          success: false,
          msg: error,
        }))
    },

    async uploadImage(ctx, imageData) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const storage = getStorage()
      const storageRef = ref(
        storage,
        `images/${userData.user.uid}/${imageData.itemId}/${Date.now()}-${imageData.image.name}`,
      )

      return uploadBytes(storageRef, imageData.image).then(() => getDownloadURL(storageRef).then(url => url))
    },

    async getMaterial(ctx, itemId) {
      const response = await getDoc(doc(dbapp, 'materials', itemId))

      return response
    },

    async addMaterial(ctx, content) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      content.payload.sold = false
      content.payload.posted_by = userData.user.uid
      content.payload.email = userData.user.email
      content.payload.created_at = Timestamp.fromDate(new Date())

      // Location
      if (content.payload.location !== undefined && content.payload.location.name !== undefined) {
        content.payload.address = content.payload.location.formatted_address
        content.payload.name_of_establishment = content.payload.location.name
        const lat = content.payload.location.geometry.location.lat()
        const lng = content.payload.location.geometry.location.lng()
        console.log(lat, lng)
        content.payload.location = new GeoPoint(lat, lng)
      }

      return addDoc(collection(dbapp, 'materials'), content.payload).then(snapshot => {
        console.log(snapshot)

        return snapshot
      })
    },

    async updateMaterial(ctx, content) {
      if (content.payload.published !== undefined) {
        if (content.payload.published) {
          content.payload.published_on = Timestamp.fromDate(new Date())
        }
      }
      if (content.payload.sold !== undefined) {
        if (content.payload.sold) {
          content.payload.sold_on = Timestamp.fromDate(new Date())
        }
      }
      if (content.payload.id !== undefined) {
        delete content.payload.id
        delete content.payload.published
        delete content.payload.published_on
        delete content.payload.sold
        delete content.payload.sold_on
        delete content.payload.created_at
      }
      if (content.payload.location !== undefined && content.payload.location.name !== undefined) {
        content.payload.address = content.payload.location.formatted_address
        content.payload.name_of_establishment = content.payload.location.name
        let lat = 0.0
        try {
          lat = content.payload.location.geometry.location.lat()
        } catch {
          try {
            lat = content.payload.location.latitude
          } catch {}
        }
        let lng = 0.0
        try {
          lng = content.payload.location.geometry.location.lng()
        } catch {
          try {
            lat = content.payload.location.longitude
          } catch {}
        }
        console.log(lat, lng)
        content.payload.location = new GeoPoint(lat, lng)
      }
      const response = await updateDoc(doc(dbapp, 'materials', content.itemId), content.payload)

      return response
    },

    async deleteMaterial(ctx, itemId) {
      const response = await deleteDoc(doc(dbapp, 'materials', itemId))

      return response
    },

    deleteMaterials(ctx, itemIds) {
      itemIds.forEach(itemId => {
        console.log(`Deleting Id: ${itemId.id}`)
        deleteDoc(doc(dbapp, 'materials', itemId.id))
      })
    },

    async deleteMaterial(ctx, itemId) {
      console.log(`Deleting Material Id: ${itemId.id}`)
      const response = await updateDoc(doc(dbapp, 'materials', itemId.id), { "deleted": true })

      return response
    },

    async fetchMaterialsByUser(ctx) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const q = query(
        collection(dbapp, 'materials'),
        where('posted_by', '==', userData.user.uid),
        // orderBy('created_at'),
      )
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()
        deet.id = document.id
        results.push(deet)
      })

      return results
    },

    async fetchMaterials(ctx) {
      const q = query(collection(dbapp, 'materials'), where('published', '==', true))
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()
        deet.id = document.id
        if (!deet.deleted) {
          results.push(deet)
        }
      })

      return results
    },

    async fetchMarkers(ctx) {
      const q = query(collection(dbapp, 'materials'), where('post_type', '==', 'selling')) //where('published', '==', true), where('sold', '==', false))
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()
        deet.id = document.id
        deet.pit = false
        deet.highlight = false
        if (deet.location !== null && deet.location !== undefined) {
          deet.markerLocation = {
            lat: deet.location._lat,
            lng: deet.location._long,
          }
          // Check marker
          if (deet.markerLocation.lat === undefined) {
            deet.markerLocation = {
              lat: deet.location.latitude,
              lng: deet.location.longitude,
            }  
          }
          if (!deet.deleted) {
            results.push(deet)
          }
        }
      })

      return results
    },

    async fetchTakeMarkers(ctx) {
      const q = query(collection(dbapp, 'materials'), where('post_type', '==', 'looking'))
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()
        deet.id = document.id
        deet.pit = false
        deet.highlight = false

        if (deet.location !== undefined && deet.location !== null) {
          deet.markerLocation = {
            lat: deet.location._lat,
            lng: deet.location._long,
          }
          // Check marker
          if (deet.markerLocation.lat === undefined) {
            deet.markerLocation = {
              lat: deet.location.latitude,
              lng: deet.location.longitude,
            }  
          }
          if (!deet.deleted) {
            results.push(deet)
          }
        }
      })

      return results
    },

    async fetchPitMarkers(ctx) {
      const q = query(collection(dbapp, 'quarries'), where('location', '!=', null))
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()
        deet.id = document.id
        deet.pit = true
        deet.highlight = false
        deet.markerLocation = {
          lat: deet.location._lat,
          lng: deet.location._long,
        }
        results.push(deet)
      })

      return results
    },

    async fetchOthers(ctx) {
      const q = query(collection(dbapp, 'others'))
      const querySnap = await getDocs(q)
      const results = []

      querySnap.forEach(document => {
        const deet = document.data()

        const otherSubtype = {
          itemType: 'Other',
          description: deet.description,
          itemSubtype: document.id,
          image: '',
        }
        console.log(otherSubtype)

        results.push(otherSubtype)
      })

      return results
    },

    async addOther(ctx, content) {
      return setDoc(doc(dbapp, 'others', content), {
        description: `--- ${content}`
      }).then(snapshot => {
        console.log(snapshot)

        return snapshot
      })
    },
  },
}
